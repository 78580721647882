<script>
export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card bg-light text-dark">
    <div class="card-body py-3">
      <div v-if="type == 'center'" class="text-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-success rounded text-dark px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-danger rounded text-dark px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background-color: #e0e0e0;
}
</style>